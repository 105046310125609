// Constants
const ATTRIBUTE = {
  EMBED_KEY: 'data-pledge-embed',
  SKIP_INTRO: 'data-pledge-skip-intro',
  BORDER_ENABLED: 'data-border-enabled',
};
const SEARCH_PARAM = {
  EMBED_KEY: 'clcEmbedKey',
  SKIP_INTRO: 'clcSkipIntroScreen',
  SHARE_ENABLED: 'clcShareEnabled',
};
const DEFAULT_IFRAME_SOURCE = 'https://calculator.pledge.io';
const SUPPORTED_TLDS = ['pledge.io', 'pledge.dev', 'pledge.localhost'];

(() => {
  // Set up the iframe
  const scriptElement = document.querySelector(`script[${ATTRIBUTE.EMBED_KEY}]`);
  if (!scriptElement) {
    throw new Error('No script element with data-pledge-embed attribute found');
  }

  const embedKey = scriptElement.getAttribute(ATTRIBUTE.EMBED_KEY);
  if (!embedKey) {
    throw new Error('No data-pledge-embed attribute found on script element');
  }

  const src = scriptElement.getAttribute('src');
  if (!src) {
    throw new Error('No src attribute found on script element');
  }
  let scriptSource = new URL(src);

  if (!SUPPORTED_TLDS.some((tld) => scriptSource.hostname.endsWith(tld))) {
    scriptSource = new URL(DEFAULT_IFRAME_SOURCE);
  }

  // Build the calculator URL
  const calculatorURL = new URL(`${scriptSource.protocol}//${scriptSource.host}`);
  calculatorURL.searchParams.append(SEARCH_PARAM.EMBED_KEY, embedKey);

  // Add the optional parameters
  if (scriptElement.getAttribute(ATTRIBUTE.SKIP_INTRO) === 'true') {
    calculatorURL.searchParams.append(SEARCH_PARAM.SKIP_INTRO, 'true');
  }

  // Build the iframe styles
  const iframeStyles: Record<string, string> = {
    width: '100%',
    height: '100%',
    border: 'none',
  };

  const borderEnabled = scriptElement.getAttribute(ATTRIBUTE.BORDER_ENABLED) ?? 'true';
  if (borderEnabled === 'true') {
    // @ts-expect-error -- enabling strict mode
    iframeStyles.border = '1px solid #e2e2e2';
    iframeStyles['border-radius'] = '4px';
  }

  // Build the iframe
  const iframeElement = document.createElement('iframe');
  iframeElement.setAttribute('src', calculatorURL.toString());
  iframeElement.setAttribute(
    'style',
    Object.entries(iframeStyles)
      .map(([key, value]) => `${key}: ${value}`)
      .join('; '),
  );
  iframeElement.setAttribute('allow', 'clipboard-write; fullscreen');

  scriptElement.replaceWith(iframeElement);
})();
